class UtilService {
  constructor() {

  }

getDate(utcSeconds){
 var monthName =   ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]; 
    var d = new Date(0); 
    d.setUTCSeconds(Math.round(parseInt(utcSeconds)/1000));
  return (d.getDate() + " " + monthName[d.getMonth()] + " " + d.getFullYear());
}

getSlot(slot){
    return timeslot[slot].slot;
}


  
  
  
  


}